body {
    margin: 0;
    padding: 0;
    font-family: 'CircularStd', Roboto, Arial, Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-print-color-adjust: exact; 
  }

  a {
    background-color: transparent;
    text-decoration: none;
    color:inherit;
  }

a:focus,
input:focus,
button:focus {
  outline: none;
}

table {
  border-collapse: collapse;
}

tr, td {
  padding: 5px;
}